import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { blogPosts } from "../blogs";

type BlogPost = {
  id: number;
  title: string;
  excerpt: string;
  content: string;
  coverImage: string;
  readTime: string;
  category: string;
};

function Blogs() {
  const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null);

  // Categories for filtering
  const categories = [
    "All",
    "Tips",
    "Trends",
    "Safety",
    "Influencers",
    "Customer Insights",
    "Marketing",
    "Innovation",
  ];
  const [activeCategory, setActiveCategory] = useState("All");

  // Filter posts based on active category
  const filteredPosts =
    activeCategory === "All"
      ? blogPosts
      : blogPosts.filter((post) => post.category === activeCategory);

  const openModal = (post: BlogPost) => {
    setSelectedPost(post);
    document.body.style.overflow = "hidden"; // Prevent scrolling on the background
  };

  const closeModal = () => {
    setSelectedPost(null);
    document.body.style.overflow = "auto"; // Restore scrolling
  };

  // Handle ESC key to close modal
  React.useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape" && selectedPost) {
        closeModal();
      }
    };

    window.addEventListener("keydown", handleEscKey);
    return () => window.removeEventListener("keydown", handleEscKey);
  }, [selectedPost]);

  return (
    <div className="w-full max-w-7xl mx-auto p-4 sm:p-6 md:p-8 ">
      {/* Page Header */}
      <header className="mb-12">
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-500">
          Blogs
        </h1>
        <p className="text-center text-gray-300 max-w-2xl mx-auto text-lg">
          Explore the latest insights, trends, and tips on community-driven
          shopping in the beauty and cosmetics industry.
        </p>
      </header>

      {/* Category Filters */}
      <div className="flex flex-wrap justify-center gap-2 mb-8">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={`px-4 py-2 rounded-full transition-all duration-300 
              ${
                activeCategory === category
                  ? "bg-[#2C3E50] text-white"
                  : "bg-[#1E2A38] text-gray-300 hover:bg-[#2A3A4A]"
              }`}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Blog Cards Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredPosts.map((post) => (
          <article
            key={post.id}
            onClick={() => openModal(post)}
            className="relative bg-gradient-to-br from-[#1E2A38] to-[#2C3E50] rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:-translate-y-2 hover:shadow-2xl cursor-pointer group"
          >
            {/* Cover Image */}
            <div className="h-48 overflow-hidden">
              <img
                src={post.coverImage}
                alt={post.title}
                className="w-full h-full object-cover object-center transform transition-transform duration-500 group-hover:scale-110"
              />

              {/* Category Badge */}
              <div className="absolute top-4 right-4">
                <span className="bg-[#2C3E50] text-white text-xs px-2 py-1 rounded-full">
                  {post.category}
                </span>
              </div>
            </div>

            {/* Content */}
            <div className="p-6 relative z-10 border-t border-gray-700/30">
              <h2 className="text-xl font-bold text-white mb-3 line-clamp-2 group-hover:text-white transition-colors duration-300">
                {post.title}
              </h2>
              <p className="text-gray-300 text-sm mb-4 line-clamp-3">
                {post.excerpt}
              </p>
              <div className="flex items-center justify-between">
                <span className="inline-flex items-center text-white font-medium group-hover:translate-x-1 transition-transform duration-300">
                  Read Article
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 ml-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </span>
                <span className="text-xs text-gray-400">{post.readTime}</span>
              </div>
            </div>
          </article>
        ))}
      </div>

      {/* No Results Message */}
      {filteredPosts.length === 0 && (
        <div className="text-center py-12">
          <p className="text-xl text-gray-400">
            No articles found in this category.
          </p>
          <button
            onClick={() => setActiveCategory("All")}
            className="mt-4 px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors"
          >
            View All Articles
          </button>
        </div>
      )}

      {/* Modal */}
      {selectedPost && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 md:p-8">
          {/* Backdrop with blur */}
          <div
            className="absolute inset-0 bg-black/80 backdrop-blur-sm"
            onClick={closeModal}
          />

          {/* Modal Content */}
          <div className="relative bg-gradient-to-b from-[#1E2A38] to-[#2A3A4A] rounded-xl shadow-2xl max-w-4xl w-full max-h-[85vh] overflow-hidden flex flex-col animate-fadeIn">
            {/* Header Image */}
            <div className="h-48 sm:h-64 overflow-hidden relative">
              <img
                src={selectedPost.coverImage}
                alt={selectedPost.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-[#1E2A38] to-transparent opacity-70"></div>

              {/* Category Badge */}
              <div className="absolute top-4 left-4">
                <span className="bg-[#2A3A4A] text-white text-xs px-2 py-1 rounded-full">
                  {selectedPost.category}
                </span>
              </div>

              {/* Close Button */}
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 bg-black/30 hover:bg-black/50 text-white p-2 rounded-full transition-colors"
                aria-label="Close modal"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Content */}
            <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800 transform transition-all duration-300 p-6 sm:p-8">
              <h2 className="text-2xl sm:text-3xl font-bold text-white mb-2">
                {selectedPost.title}
              </h2>

              <div className="flex items-center text-gray-400 text-sm mb-6">
                <span>{selectedPost.readTime}</span>
                <span className="mx-2">•</span>
                <span>March 21, 2025</span>
              </div>

              <div className="prose prose-invert prose-lg max-w-none text-white">
                <ReactMarkdown>{selectedPost.content}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Blogs;
