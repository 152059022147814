"use client";

import { useEffect, useState } from "react";
import "../App.css";
import "./styles/main.css";
import Appstore from "../assets/app-store.png";
import Playstore from "../assets/play-store.png";
import bgImage from "../assets/bg-image.png";

import mobileLogo from "../assets/mobile_logo.svg";
import { Link } from "react-router-dom";
import { AppStoreURL, PlayStoreURL } from "./redirect";

// Import the wave SVG for the footer
import wave from "../assets/wave.svg";

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="three-body">
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
      </div>
    );
  }

  return (
    <div className=" h-[94vh] flex flex-col text-white">
      {/* Main content - Desktop */}
      <div
        className="flex-grow w-full px-2 md:px-20 flex flex-col md:flex-row gap-5 justify-between items-center overflow-hidden"
        style={{ position: "relative" }} // Ensure positioning context
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: 0.08, // 20% opacity (0.0 to 1.0 scale)
          }}
        />
        {/* Left Section: Text and Buttons */}
        <div className="flex flex-col gap-5 max-w-[100%] md:max-w-[50%] justify-center items-center pt-14 md:pt-0">
          <h1
            className="text-3xl md:text-6xl font-normal"
            style={{ fontFamily: "Lexend" }}
          >
            The Social Commerce
          </h1>
          <div className="flex flex-col justify-center items-center">
            <p className="text-[#CCCCCC] text-sm md:text-lg text-center">
              We are an AI-powered platform revolutionizing the world of social
              commerce by seamlessly blending personalized shopping experiences
              with captivating storytelling. As an AI-driven company, we harness
              intelligent technology to empower brands, consumers, and
              influencers, creating a dynamic ecosystem where shopping becomes
              smarter, more intuitive, and deeply connected.
            </p>
          </div>
          <div className="mt-6 space-x-4 hidden md:flex">
            <a href={AppStoreURL} target="_blank" rel="noreferrer">
              <img
                src={Appstore || "/placeholder.svg"}
                alt="Download on the App Store"
                className="h-10 md:h-16 object-contain"
              />
            </a>
            <a href={PlayStoreURL} target="_blank" rel="noreferrer">
              <img
                src={Playstore || "/placeholder.svg"}
                alt="Get it on Google Play"
                className="h-10 md:h-16 object-contain"
              />
            </a>
          </div>
        </div>

        {/* Right Section: Icons */}
        <div className="relative flex flex-col gap-12 md:gap-0 items-center justify-center w-full md:w-1/2 h-[57vh] md:h-[70vh]">
          <img
            src="/options.svg"
            alt="Socco Options"
            className="object-contain h-[70%]"
          />
          <div className="mt-6 space-x-4 md:hidden flex gap-8">
            <a href={AppStoreURL} target="_blank" rel="noreferrer">
              <img
                src={Appstore || "/placeholder.svg"}
                alt="Download on the App Store"
                className="h-10 md:h-16 object-contain"
              />
            </a>
            <a href={PlayStoreURL} target="_blank" rel="noreferrer">
              <img
                src={Playstore || "/placeholder.svg"}
                alt="Get it on Google Play"
                className="h-10 md:h-16 object-contain"
              />
            </a>
          </div>
        </div>
      </div>

      {/* Footer with wave */}
      <footer className="w-full text-white mt-auto relative">
        {/* Footer content - positioned above the wave with padding to ensure visibility */}
        <div className="px-6 md:px-20 py-8 flex flex-col md:flex-row justify-between items-center relative z-10">
          <p className="text-sm mb-4 md:mb-0">© 2025 Wholifi LLC, USA</p>
          <div className="flex gap-6">
            <Link to="/privacy" className="text-sm hover:text-gray-300">
              Privacy
            </Link>
            <Link to="/terms" className="text-sm hover:text-gray-300">
              Terms & Conditions
            </Link>
          </div>
        </div>

        {/* Wave SVG - at the bottom */}
        <div className="w-full overflow-hidden absolute bottom-0 left-0">
          <img src={wave} alt="Wave" className="w-full h-auto" />
        </div>
      </footer>
    </div>
  );
}

export default Home;
