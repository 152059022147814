import { Mail, MapPin, Phone, ChevronDown, ChevronUp } from "lucide-react";
import React, { useState } from "react";

function Contact() {
  // State to track which accordion is open (null means all closed)
  const [openAccordion, setOpenAccordion] = useState(null);

  // Toggle function for accordions
  const toggleAccordion = (index: any) => {
    if (openAccordion === index) {
      setOpenAccordion(null); // Close if already open
    } else {
      setOpenAccordion(index); // Open the clicked one, closing others
    }
  };

  // FAQ data structure
  const faqCategories = [
    {
      title: "General Questions",
      items: [
        {
          question: "What is Socco?",
          answer:
            "We are a community-driven shopping platform focused on beauty and cosmetic products. Our goal is to help you discover the latest products and connect with brands, all while enjoying an interactive, engaging shopping experience. We are a young startup and continuously adding new features to make your shopping experience even better.",
        },
        {
          question: "How does it work?",
          answer:
            "On our platform, you can explore a wide variety of beauty products, engage with recommendations from other users, and make purchases directly. Our community-driven shopping approach allows you to connect with like-minded beauty enthusiasts, influencers, and brands, giving you personalized product discoveries.",
        },
        {
          question: "Is the platform free to use?",
          answer:
            "Yes! Our platform is free for users to explore, discover new products, and make purchases. Sellers and influencers can also use our premium features to increase visibility and engagement with their products.",
        },
      ],
    },
    {
      title: "Account and Profile",
      items: [
        {
          question: "Do I need to create an account to browse products?",
          answer:
            "You don't need to create an account to browse products. However, if you want to save your favorite products, receive personalized recommendations, or make purchases, we recommend creating an account.",
        },
        {
          question: "How can I create an account?",
          answer:
            'Simply click on the "Sign Up" button and provide your email, name, and password. You can also sign up using your social media accounts like Facebook or Google for a quicker registration process.',
        },
        {
          question: "How do I update my profile?",
          answer:
            "To update your profile, go to your account settings. You can update your name, profile picture, contact info, and other preferences.",
        },
      ],
    },
    {
      title: "Product Discovery and Shopping",
      items: [
        {
          question: "How can I find the best beauty products?",
          answer:
            "You can discover beauty products by browsing curated collections, reading user reviews, and checking out trending items. Our community-driven shopping model means you'll always find the products most loved and recommended by fellow beauty enthusiasts and influencers.",
        },
        {
          question: "Can I purchase products directly on the platform?",
          answer:
            "Yes! Once you find a product you love, you can add it to your cart and proceed with secure checkout directly on the platform.",
        },
        {
          question: "Are there any discounts or promotions?",
          answer:
            "Yes, we offer regular discounts and special promotions! Check our platform for exclusive deals from your favorite brands and products.",
        },
        {
          question: "How do I track my order?",
          answer:
            "After placing your order, you'll receive a confirmation email with tracking details. You can also monitor the status of your order in your account's Order History section.",
        },
      ],
    },
    {
      title: "Sellers and Brands",
      items: [
        {
          question: "How can I sell my beauty products on the platform?",
          answer:
            "If you're a brand or seller, you can easily apply through our seller portal. Once approved, you'll be able to list your products and engage with our active community of beauty enthusiasts.",
        },
        {
          question: "Are there any fees for selling on the platform?",
          answer:
            "There are listing fees for sellers, and we offer various packages to help you increase your brand's visibility. These packages include tools to promote your products and engage with customers more effectively.",
        },
        {
          question: "What branding services do you offer to sellers?",
          answer:
            "We offer branding services to sellers that include customized product pages, promotional campaigns, and tailored social media strategies to help enhance visibility and connect your products with the right audience. We provide the tools to make your brand stand out in our community-driven shopping environment.",
        },
        {
          question: "How can I promote my products?",
          answer:
            "Sellers can use interactive advertising features to promote their products, create exclusive deals, or even collaborate with influencers within our community to reach a wider audience.",
        },
      ],
    },
    {
      title: "Influencers and Engagement",
      items: [
        {
          question: "How can I become an influencer on the platform?",
          answer:
            "We are always looking for influencers who are passionate about beauty and skincare. You can apply through our influencer sign-up page, where you'll be able to share your favorite products and promote them to your followers, while earning commissions on sales.",
        },
        {
          question: "How do I make money as an influencer?",
          answer:
            "As an influencer, you can earn commissions through affiliate links, promote special discount codes, and collaborate with sellers for brand partnerships. Your recommendations drive real sales, and you get paid for every purchase made through your links.",
        },
        {
          question: "How do I track my earnings as an influencer?",
          answer:
            "You can track your earnings and performance in real-time through your influencer dashboard. This dashboard shows you all the sales generated through your recommendations, as well as any upcoming collaborations.",
        },
      ],
    },
    {
      title: "Security and Privacy",
      items: [
        {
          question: "Is my payment information secure?",
          answer:
            "Absolutely! We use industry-standard encryption to ensure that all your payment details are kept secure. Your privacy and security are our top priority.",
        },
        {
          question: "How is my personal information protected?",
          answer:
            "We follow strict data protection guidelines to ensure your personal information is safe. We don't share your data with any third parties unless necessary to process your orders or fulfill your requests. For more details, please refer to our Privacy Policy.",
        },
      ],
    },
    {
      title: "Shipping and Returns",
      items: [
        {
          question: "How do I know if a product ships to my location?",
          answer:
            "During checkout, you'll be able to see whether a product can be shipped to your location. Shipping policies may vary by seller.",
        },
        {
          question: "How long does shipping take?",
          answer:
            "Shipping times vary depending on the product and your location. You will receive an estimated delivery date during checkout, and you can track your order once it's shipped.",
        },
        {
          question: "Can I return products?",
          answer:
            "Yes, returns are possible, but they depend on the seller's return policy. Please check the return policy listed on each product page for details. Make sure you follow the instructions to ensure a smooth return process.",
        },
      ],
    },
    {
      title: "Customer Support",
      items: [
        {
          question: "How can I contact customer support?",
          answer:
            "If you have any questions or need assistance, you can reach our customer support team by going to the Help section in your account. You can also contact us through live chat or email.",
        },
        {
          question: "What should I do if I have an issue with my order?",
          answer:
            "If you have any issues with your order, such as incorrect or damaged items, please contact customer support as soon as possible. We'll work to resolve the issue quickly.",
        },
      ],
    },
    {
      title: "Technical Issues",
      items: [
        {
          question:
            "I'm having trouble logging into my account. What should I do?",
          answer:
            'If you\'re unable to log in, click on the "Forgot Password" link to reset your password. If you continue experiencing issues, contact our support team for assistance.',
        },
        {
          question: "Why is the platform running slowly or showing errors?",
          answer:
            "If you experience technical issues, try clearing your browser cache or switching to a different browser. If the problem persists, please reach out to our support team, and we'll help you resolve it.",
        },
      ],
    },
    {
      title: "Miscellaneous",
      items: [
        {
          question: "Can I gift products to someone else?",
          answer:
            "Yes! You can purchase products as gifts and have them shipped directly to the recipient's address. Just enter their shipping information at checkout.",
        },
        {
          question: "Is there a mobile app for the platform?",
          answer:
            "Yes, we have a mobile app available for both iOS and Android. You can shop, track orders, and discover new products on the go.",
        },
        {
          question:
            "How can I get involved as an early user, seller, or influencer?",
          answer:
            "We're looking for early users, sellers, and influencers to join our growing community. If you're interested, sign up now and gain exclusive access to special features, benefits, and opportunities to shape the future of the platform.",
        },
      ],
    },
  ];

  // Accordion component with transitions
  const Accordion = ({ title, items, index }: any) => {
    const isOpen = openAccordion === index;

    return (
      <div className="border-b border-gray-700 transition-all duration-300 ease-in-out">
        <button
          onClick={() => toggleAccordion(index)}
          className="w-full py-4 px-2 flex justify-between items-center focus:outline-none group hover:bg-gray-800/50 transition-colors duration-200"
        >
          <h3 className="text-lg font-semibold text-white group-hover:text-white transition-colors duration-200">
            {title}
          </h3>
          <div className="transform transition-transform duration-300 ease-in-out">
            <ChevronDown
              className={`w-6 h-6 text-white group-hover:text-white transition-all duration-300 ease-in-out ${
                isOpen ? "rotate-180" : "rotate-0"
              }`}
            />
          </div>
        </button>
        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out ${
            isOpen
              ? "max-h-[1000px] opacity-100" // Use a large max-height for smooth expansion
              : "max-h-0 opacity-0"
          }`}
        >
          <div className="pb-4 px-4 space-y-4 transform transition-all duration-300 ease-in-out">
            {items.map((item: any, idx: any) => (
              <div
                key={idx}
                className={`mb-4 transition-all duration-300 ease-in-out ${
                  isOpen
                    ? "translate-y-0 opacity-100"
                    : "-translate-y-2 opacity-0"
                }`}
              >
                <h4 className="text-white font-medium mb-1 transform transition-all duration-200 hover:scale-105">
                  {item.question}
                </h4>
                <p className="text-gray-300 text-sm leading-relaxed">
                  {item.answer}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-center items-center w-full mx-auto min-h-[calc(100vh-80px)]">
      <div className="flex flex-col w-full max-w-7xl px-0 sm:px-2 md:px-0 py-0 sm:py-0 mx-auto text-white">
        {/* Page Title */}
        <h1 className="text-3xl sm:text-4xl font-semibold mb-12 text-center">
          Support
        </h1>

        <div className="flex flex-col-reverse sm:flex-col lg:flex-row gap-5 lg:gap-8">
          {/* Left Section - Contact Info */}
          <div className="w-full lg:w-1/3 space-y-6 bg-gradient-to-br from-[#1E2A38] to-[#2C3E50] p-6 rounded-lg">
            {/* Small Header */}
            <p className="text-white text-lg font-semibold">
              How can we help you?
            </p>

            {/* Main Heading */}
            <h2 className="text-3xl sm:text-4xl font-bold text-white">
              Contact us
            </h2>

            {/* Description */}
            <p className="text-gray-300 text-lg">
              We are here to help and answer any questions you might have. We
              look forward to hearing from you!
            </p>

            {/* Contact Details */}
            <div className="space-y-4 mt-8">
              {/* Address */}
              <div className="flex items-start gap-3">
                <MapPin className="w-6 h-6 text-gray-400 flex-shrink-0" />
                <p className="text-gray-200">Wholifi LLC, USA</p>
              </div>

              {/* Phone */}
              <div className="flex items-start gap-3">
                <Phone className="w-6 h-6 text-gray-400 flex-shrink-0" />
                <p className="text-gray-200">972-850-6664</p>
              </div>

              {/* Email */}
              <div className="flex items-start gap-3">
                <Mail className="w-6 h-6 text-gray-400 flex-shrink-0" />
                <a
                  href="mailto:hello@socco.io"
                  className="text-gray-200 hover:underline hover:text-white transition-colors duration-200"
                >
                  hello@socco.io
                </a>
              </div>
            </div>
          </div>

          {/* Right Section - FAQ Accordions */}
          <div className="w-full lg:w-2/3">
            <div className="bg-gradient-to-br from-[#1E2A38] to-[#2C3E50] rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-6 text-white">
                Frequently Asked Questions
              </h2>

              <div className="space-y-1 max-h-[600px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800 pr-2">
                {faqCategories.map((category, index) => (
                  <Accordion
                    key={index}
                    title={category.title}
                    items={category.items}
                    index={index}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
