import { useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  return (
    <nav className="flex justify-between items-center w-full py-4 px-6 md:px-20 text-white">
      <Link to="/" className="flex items-center">
        <div className="relative w-10 h-10">
          <img src="/logo.png" alt="Socco Logo" className="object-contain" />
        </div>
        <span
          className="ml-2 text-2xl font-bold"
          style={{ fontFamily: "Lexend" }}
        >
          Socco
        </span>
      </Link>

      {/* Desktop Menu */}
      <div className="hidden md:flex gap-8">
        <Link
          to="/blogs"
          className="text-white hover:text-gray-300 transition-colors"
        >
          Blog
        </Link>
        {/* <Link
          to="/"
          className="text-white hover:text-gray-300 transition-colors"
        >
          FAQ
        </Link> */}
        <Link
          to="/"
          className="text-white hover:text-gray-300 transition-colors"
        >
          Jobs
        </Link>
        <a
          href="https://partner.socco.io/login"
          className="text-white hover:text-gray-300 transition-colors"
        >
          Become Partner
        </a>
        <Link
          to="/support"
          className="text-white hover:text-gray-300 transition-colors"
        >
          Support
        </Link>
      </div>

      {/* Mobile Menu Icon */}
      <button
        className="md:hidden flex flex-col justify-center items-center w-8 h-8 space-y-1.5 z-20"
        onClick={toggleSideMenu}
        aria-label="Toggle menu"
      >
        <span
          className={`block w-6 h-0.5 bg-white transition-transform duration-300 ${
            isSideMenuOpen ? "transform rotate-45 translate-y-2" : ""
          }`}
        ></span>
        <span
          className={`block w-6 h-0.5 bg-white transition-opacity duration-300 ${
            isSideMenuOpen ? "opacity-0" : "opacity-100"
          }`}
        ></span>
        <span
          className={`block w-6 h-0.5 bg-white transition-transform duration-300 ${
            isSideMenuOpen ? "transform -rotate-45 -translate-y-2" : ""
          }`}
        ></span>
      </button>

      {/* Side Menu */}
      <div
        className={`fixed top-0 left-0 w-64 h-full bg-[#16202A] backdrop-blur-md bg-opacity-90 z-50 transform transition-transform duration-300 ease-in-out ${
          isSideMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex flex-col pt-20 px-6">
          <Link
            to="/blogs"
            className="py-3 text-lg text-white hover:text-gray-300 transition-colors border-b border-gray-800"
            onClick={toggleSideMenu} // Close menu on click
          >
            Blog
          </Link>
          <Link
            to="/"
            className="py-3 text-lg text-white hover:text-gray-300 transition-colors border-b border-gray-800"
            onClick={toggleSideMenu}
          >
            FAQ
          </Link>
          <Link
            to="/"
            className="py-3 text-lg text-white hover:text-gray-300 transition-colors border-b border-gray-800"
            onClick={toggleSideMenu}
          >
            Jobs
          </Link>
          <a
            href="https://partner.socco.io/login"
            className="py-3 text-lg text-white hover:text-gray-300 transition-colors border-b border-gray-800"
          >
            Become Partner
          </a>
          <Link
            to="/support"
            className="py-3 text-lg text-white hover:text-gray-300 transition-colors"
            onClick={toggleSideMenu}
          >
            Contact Us
          </Link>
        </div>
      </div>

      {/* Overlay when menu is open */}
      {isSideMenuOpen && (
        <div className="fixed inset-0 z-10" onClick={toggleSideMenu}></div>
      )}
    </nav>
  );
}
