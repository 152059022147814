import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import hand from "../assets/iphone-15-pro-hand.png";
import Appstore from "../assets/app-store.png";
import Playstore from "../assets/play-store.png";

export const PlayStoreURL =
  "https://play.google.com/store/apps/details?id=com.io.socco";
export const AppStoreURL = "https://apps.apple.com/app/id6737239367";

const RedirectToStore = () => {
  const [isMobile, setIsMobile] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // hide scrollbar
    document.body.style.height = "100%";
    document.body.style.overflow = "hidden";

    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      // Android user -> Redirect to Play Store
      window.location.href = PlayStoreURL;
      navigate("/");
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      // iOS user -> Redirect to App Store
      window.location.href = AppStoreURL;
      navigate("/");
    } else {
      // Desktop user
      setIsMobile(false);
    }
  }, []);

  if (isMobile === null) {
    return <p>Detecting device...</p>;
  }

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between min-h-screen px-24 overflow-hidden pb-24">
      <div className="max-w-3xl">
        <h1
          className="md:text-2xl lg:text-5xl font-bold text-black mb-2"
          style={{
            fontFamily: "Lexend",
            fontWeight: "bold",
          }}
        >
          Download the SOCCO
        </h1>
        <h1
          className="md:text-2xl lg:text-5xl font-bold text-black mb-12"
          style={{
            fontFamily: "Lexend",
            fontWeight: "bold",
          }}
        >
          App Now
        </h1>

        <p
          className="mt-4 md:text-md lg:text-lg text-black"
          style={{
            fontFamily: "Lexend",
            fontWeight: "normal",
          }}
        >
          Join the fastest-growing Social Marketplace. Connect with consumers,
          showcase your products, and grow your business.
        </p>
      </div>

      <div className="flex flex-col justify-center items-center">
        <img
          src={hand}
          alt="Socco App"
          className="w-auto h-96 object-contain"
        />
        <div className="mt-6 flex lg:space-x-12 space-x-6">
          <a href={AppStoreURL} target="_blank" className="" rel="noreferrer">
            <img
              src={Appstore}
              alt="Download on the App Store"
              className="lg:h-16 h-12 object-contain"
            />
          </a>
          <a href={PlayStoreURL} target="_blank" className="" rel="noreferrer">
            <img
              src={Playstore}
              alt="Get it on Google Play"
              className="lg:h-16 h-12 object-contain"
            />
          </a>
        </div>
      </div>

      {/* footer */}
      <div
        className="absolute gap-5 flex"
        style={{
          bottom: "5rem",
        }}
      >
        <Link to="/privacy">
          <p
            style={{
              fontFamily: "Lexend",
            }}
          >
            Privacy
          </p>
        </Link>

        <Link to="/terms">
          <p
            style={{
              fontFamily: "Lexend",
            }}
          >
            Terms & Conditions
          </p>
        </Link>
      </div>
    </div>
  );
};

export default RedirectToStore;
