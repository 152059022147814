import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import RedirectToStore from "./pages/redirect";
import Navbar from "./components/Navbar";
import background from "./assets/background.svg";
import background2 from "./assets/background2.svg";
import { useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Contact from "./pages/contactus";
import Blogs from "./pages/blogs";
import bgImage from "./assets/bg-image.png";

function App() {
  const { pathname } = useLocation();

  return (
    <HelmetProvider>
      <div
        className="w-full h-screen bg-no-repeat bg-center bg-[#16202A] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800 relative"
        style={
          {
            // backgroundImage: `url(${bgImage})`,
            // backgroundSize: "cover",
            // overflow: pathname === "/" ? "hidden" : "auto",
            // opacity: 1,
          }
        }
      >
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/support" element={<Contact />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="*" element={<RedirectToStore />} />
        </Routes>
      </div>
    </HelmetProvider>
  );
}

export default App;
